import {ColorSchemeProvider, MantineThemeOverride} from '@mantine/core';

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  // defaultRadius: 'xl',
  primaryColor: 'pink',
  // primaryColor: 'rgb(48, 79, 254)',
  globalStyles: theme => ({
    '.mantine-RichTextEditor-typographyStylesProvider ul, .happily-rich-text-output ul':
      {
        listStyleType: 'disc',
      },
    '.mantine-RichTextEditor-typographyStylesProvider ol, .happily-rich-text-output ol':
      {
        listStyleType: 'decimal',
      },
  }),
};
