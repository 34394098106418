import * as React from 'react';

export type AuthState = {
  isAuthenticated: boolean;
  setIsAuthenticated__DO_NOT_USE: (value: boolean) => void;
};

export const AuthContext = React.createContext<AuthState>({
  isAuthenticated: false,
  setIsAuthenticated__DO_NOT_USE: () => {},
});
