import * as React from 'react';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import {getInitialPreloadedQuery, getRelayProps} from 'relay-nextjs/app';
import {getClientEnvironment} from '../lib/getClientEnvironment';
import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import {AuthContext} from '../components/auth/AuthContext';
import {MantineProvider} from '@mantine/core';
import {theme} from '../styles/theme';
import {AppProps} from 'next/app';

const clientEnv = getClientEnvironment();
const initialPreloadedQuery = getInitialPreloadedQuery({
  createClientEnvironment: () => getClientEnvironment()!,
});

function MyApp({Component, pageProps}: AppProps) {
  const relayProps = getRelayProps(pageProps, initialPreloadedQuery);
  const env = relayProps.preloadedQuery?.environment ?? clientEnv!;

  const [isAuthenticated, setIsAuthenticated] = React.useState(
    // @ts-ignore
    pageProps.__isAuthenticated ?? false,
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated__DO_NOT_USE: setIsAuthenticated,
      }}
    >
      <RelayEnvironmentProvider environment={env}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <Component {...pageProps} {...relayProps} />
        </MantineProvider>
      </RelayEnvironmentProvider>
    </AuthContext.Provider>
  );
}

export default MyApp;
